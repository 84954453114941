import React from 'react';
import { ReactComponent as IconDiscord } from './assets/icons/discord.svg';
import { ReactComponent as IconTelegram } from './assets/icons/telegram.svg';
import { ReactComponent as IconTikTok } from './assets/icons/tiktok.svg';
import { ReactComponent as IconInstagram } from './assets/icons/instagram.svg';
import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
import { ReactComponent as IconGithub } from './assets/icons/github.svg';
import logo from './assets/images/funii_munii_fullmark_05_condensed_left.svg'
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <img src={logo}/> 
          </div>
          <div className="social">
            <a href="https://discord.gg/funiimunii" title="Discord" target="_blank" rel="noopener noreferrer">
              <IconDiscord className="icon" />
            </a>
            <a href="https://t.me/+JxiQKl0CDcpiMjA0" title="Telegram" target="_blank" rel="noopener noreferrer">
              <IconTelegram className="icon" />
            </a>
            <a href="https://www.tiktok.com/@funiimunii" title="TikTok" target="_blank" rel="noopener noreferrer">
              <IconTikTok className="icon" />
            </a>
            <a href="https://instagram.com/funii.munii" title="Instagram" target="_blank" rel="noopener noreferrer">
              <IconInstagram className="icon" />
            </a>
            <a href="https://twitter.com/funiimunii" title="Twitter" target="_blank" rel="noopener noreferrer">
              <IconTwitter className="icon" />
            </a>
            {/* <a href="https://github.com/funiimunii" title="GitHub" target="_blank" rel="noopener noreferrer">
              <IconGithub className="icon" />
            </a> */}
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Get ready for change.</h1>
            <p>Website coming soon! <br/>Please check back to know more. Jump in our discord or telegram.</p>
          </div>
          <a href="https://t.me/+JxiQKl0CDcpiMjA0">
            <div className="cta">Join us on Telegram</div>
          </a>
        </div>
        <div className="footer">
          {/* <span>made by <a className="underlined" href="https://github.com/arkn98" target="_blank" rel="noopener noreferrer">arkn98</a> using <a className="underlined" href="https://reactjs.org/" title="ReactJS" target="_blank" rel="noopener noreferrer">React</a> | <a className="underlined" href="https://github.com/arkn98/coming-soon" title="GitHub repo" target="_blank" rel="noopener noreferrer">GitHub</a></span> */}
        </div>
      </div>
    );
  }
}

export default App;